/* ======================================================================== */
/* 75. sectionServices */
/* ======================================================================== */
.section-services
	background: none
.section-services__inner
	position: relative
.section-services__bg
	position: absolute
	top: 0
	left: 0
	width: calc(100% - ( (100vw - 1140px) / 2) - 20px)
	height: 100%
.section-services__counter
	+small-caps
.section-services__wrapper-item
	position: relative
	@include fluid-padding-top($min_width, $max_width, 40, 120)
	@include fluid-padding-bottom($min_width, $max_width, 40, 120)
	z-index: 50
	&:not(:last-child)
		.section-services__border-line
			position: absolute
			bottom: 0
			left: 0
			right: 0
			background-color: $borders-dark
			width: 100%
			height: 1px
			display: none

@media screen and (max-width: $md)
	.section-services__wrapper-item
		&:not(:last-child)
			.section-services__border-line
				display: block
				position: absolute
				bottom: 0
				left: 20px
				right: 20px
				background-color: $borders-dark
				width: auto
				height: 1px
	.section-services__heading
		margin-top: 0.75em
		margin-bottom: 0.75em

@media screen and (max-width: 1140px)
	.section-services__bg
		width: 100%
