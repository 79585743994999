/* ======================================================================== */
/* 35. inputSearch */
/* ======================================================================== */
.input-search
.input-search__input
	padding-right: 30px
.input-search__submit
	display: inline-block
	font-size: 18px
	width: 24px
	height: 24px
	padding: 0
	position: absolute
	right: 0
	top: 0
	bottom: 0
	margin: auto
	background: transparent
	border: none
	color: $brown-grey
	outline: none
	+trans1
	cursor: pointer
	&:hover
		color: $dark
	&:focus
		outline: none
