/* ======================================================================== */
/* 36. lazy */
/* ======================================================================== */
.lazy
	// background-color: $white
	// background: linear-gradient(135deg, $white 0%, $light 100%)
	// animation-duration: 0.9s
	// animation-iteration-count: infinite
	// animation-direction: alternate
	// animation-timing-function: ease-in-out
	background-color: rgba(136, 136, 136, .1)
.lazy_loaded
	background-color: unset
.lazy__img:not(img)
	background-size: cover
	background-position: center center
	background-repeat: no-repeat
.lazy-bg
	width: 100%
	height: 100%
	background-size: cover
	background-position: center center
	background-repeat: no-repeat
	background-color: rgba(136, 136, 136, .1)
.lazy-bg_loaded
	background-color: unset

// @keyframes loading
// 	0
// 		opacity: 0
// 	to
// 		opacity: .3
