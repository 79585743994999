/* ======================================================================== */
/* 24. form */
/* ======================================================================== */
.form
	width: 100%
.form__submit
	margin-top: 1.5em
.form__row
.form__row_submit
.form__col
	margin-top: 0.5em
	margin-bottom: 0.5em
.form__col_submit
	margin-top: 1.5em
	margin-bottom: 0
.form__error, span.wpcf7-not-valid-tip
	display: block
	font-size: 12px
	color: red
	text-align: left
	margin-top: 4px
.form__heading
	margin-top: 0
	margin-bottom: 1em

div.wpcf7-validation-errors, div.wpcf7-acceptance-missing
	border-color: red
	padding: 15px
	margin: 1.5em 0 0
	display: none !important

@media only screen and (max-width: 992px)
	.form__col_submit
		text-align: left
