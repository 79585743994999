\:root
	--font-primary: 'Roboto', sans-serif
	--font-secondary: 'Playfair Display', serif
	//
	--color-accent-primary-1: #c69f73
	--color-accent-primary-2: #ebe6dd
	--color-accent-primary-3: #c9bcae
	--color-accent-primary-4: #837567
	//
	--color-accent-secondary-1: #628ab2
	--color-accent-secondary-2: #3c556e
	--color-accent-secondary-3: #283847
	--color-accent-secondary-4: #162330
	//
	--distance-max-xsmall: 60
	--distance-max-small: 100
	--distance-max-normal: 200
	--distance-max-large: 300
	--distance-max-xlarge: 400
	//
	--distance-min-xsmall: 40
	--distance-min-small: 40
	--distance-min-normal: 60
	--distance-min-large: 100
	--distance-min-xlarge: 160
	//
	--xxl-max-font-size: 167
	--xxl-min-font-size: 58
	--xxl-line-height: 1
	//
	--xl-max-font-size: 107
	--xl-min-font-size: 58
	--xl-line-height: 1
	//
	--h1-max-font-size: 65
	--h1-min-font-size: 34
	--h1-line-height: 1.28
	//
	--h2-max-font-size: 52
	--h2-min-font-size: 30
	--h2-line-height: 1.38
	//
	--h3-max-font-size: 42
	--h3-min-font-size: 26
	--h3-line-height: 1.57
	//
	--h4-max-font-size: 33
	--h4-min-font-size: 22
	--h4-line-height: 1.61
	//
	--h5-max-font-size: 20
	--h5-min-font-size: 18
	--h5-line-height: 1.7
	//
	--h6-max-font-size: 18
	--h6-min-font-size: 16
	--h6-line-height: 1.81
	//
	--subheading-max-font-size: 13
	--subheading-min-font-size: 11
	--subheading-line-height: 1.75
	//
	--blockquote-max-font-size: 20
	--blockquote-min-font-size: 16
	--blockquote-line-height: 1.7
	//
	--paragraph-max-font-size: 16
	--paragraph-min-font-size: 16
	--paragraph-line-height: 1.75

html
	font-size: $em-base
	-webkit-font-smoothing: antialiased
	text-rendering: optimizeLegibility
	-webkit-text-rendering: optimizeLegibility
	-moz-osx-font-smoothing: grayscale
	font-kerning: none
body
	position: relative
	width: 100%
	min-width: 320px
	color: $greyish-brown
	background-color: $light
	font-family: $font-primary
	font-weight: 400
	font-size: 100%
	line-height: $line-height
	word-wrap: break-word
.body_lock-scroll
	overflow: hidden
	position: fixed
	position: sticky
	top: 0
	left: 0
	width: 100%
	height: 100%
img, iframe
	max-width: 100%
	height: auto
a
	+trans1
	color: $sandstone
	&:hover, &:focus
		text-decoration: none
		color: $brownish-grey
.page-wrapper
.page-wrapper__content
	overflow-x: hidden
[data-header-animation="intransition"]
	a
		// display: none
		pointer-events: none !important
