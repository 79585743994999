/* ======================================================================== */
/* 76. sectionServicesThemes */
/* ======================================================================== */
.section-services
	&.bg-dark, &.bg-dark-2, &.bg-dark-3
		.section-services__heading
			color: $white
		.section-services__link
			color: $pinkish-grey
			&:hover
				color: $sandstone
